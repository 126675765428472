import * as React from "react";
import tw from "twin.macro";

const Card = tw.div`flex flex-col justify-between space-y-0.5 px-2 py-1 bg-neutral-container rounded-1.5`;

Card.Icon = tw.div`text-title-lg flex-1 flex justify-center items-center`;
Card.Title = tw.div`text-title-md font-medium text-on-primary`;
Card.Content = tw.div`text-body-md`;

export default Card;
