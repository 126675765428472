import tw, { styled } from "twin.macro";

const Button = styled.button(({ outlined, hasIcon, focused }) => [
  tw`text-label-lg bg-primary py-[10px] px-2 whitespace-nowrap rounded-3 hover:bg-primary-hover focus:bg-primary-focus`,
  focused && tw`bg-primary-focus`,
  outlined &&
    tw`text-on-primary bg-light-primary border-[1px] border-outline text-label-lg py-[9px] px-[15px] hover:bg-light-primary-hover focus:bg-light-primary-focus`,
  focused && outlined && tw`bg-light-primary-focus border-on-primary`,
]);

export default Button;
