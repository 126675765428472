import * as React from "react";
import { Disclosure } from "@headlessui/react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import Card from "./Card";
import tw from "twin.macro";
import Button from "./Button";
import { useStarterWithSentences } from "../data/data";

const StarterItem = ({ data, push, isAsTurn, sendText, currentLanguage }) => {
  const enrichedData = useStarterWithSentences(data);
  const [bubbles, setBubbles] = React.useState([]);
  const handleClick = () => {
    push(bubbles, data);
  };
  React.useEffect(() => {
    if (!bubbles.length && enrichedData) {
      setBubbles(enrichedData.sentences.map((line) => line[0]));
    }
  }, [bubbles, enrichedData]);

  const createBubbleChangeHandler = (index) => (event) => {
    const { value: index2 } = event.target;
    setBubbles((b) => {
      const newBubbles = [...b];
      newBubbles[index] = enrichedData.sentences[index][index2];
      return newBubbles;
    });
  };
  return (
    <Disclosure>
      {({ open }) => (
        <React.Fragment>
          <Disclosure.Button tw="flex w-full justify-between items-center">
            <span
              css={[tw`flex-1 text-left py-1`, open && tw`text-on-default`]}
            >
              {data.t[currentLanguage]}
            </span>
            <span tw="text-on-primary w-3 h-3">
              {open ? (
                <MdArrowDropUp size="100%" />
              ) : (
                <MdArrowDropDown size="100%" />
              )}
            </span>
          </Disclosure.Button>
          <Disclosure.Panel tw="mt-2">
            {enrichedData &&
              enrichedData.sentences.map((line, index) => {
                if (line.length === 1) {
                  return (
                    <Card key={index} css={[index > 0 && tw`mt-1`]}>
                      <Card.Content tw="flex">
                        <span tw="flex-grow">{line[0].t[currentLanguage]}</span>
                      </Card.Content>
                    </Card>
                  );
                }
                return (
                  <Card
                    key={index}
                    css={[tw`p-0 relative space-y-0`, index > 0 && tw`mt-1`]}
                  >
                    <Card.Content>
                      <select
                        tw="block w-full bg-transparent pl-2 pr-5 py-1 appearance-none"
                        onChange={createBubbleChangeHandler(index)}
                      >
                        {line.map((sentence, j) => (
                          <option key={j} value={j}>
                            {sentence.t[currentLanguage]}
                          </option>
                        ))}
                      </select>
                      <MdArrowDropDown
                        size={"24px"}
                        tw="absolute right-1 top-1 pointer-events-none"
                      />
                    </Card.Content>
                  </Card>
                );
              })}
            <div tw="flex justify-end mt-2">
              <Button
                onClick={handleClick}
                css={[
                  isAsTurn && tw`bg-first text-on-first`,
                  !isAsTurn && tw`bg-second text-on-second`,
                ]}
              >
                {sendText}
              </Button>
            </div>
          </Disclosure.Panel>
          <div tw="border-t-[1px] border-outline mt-1" aria-hidden="true" />
        </React.Fragment>
      )}
    </Disclosure>
  );
};

const SentenceItem = ({ data, push, isAsTurn, sendText, currentLanguage }) => {
  const handleClick = () => {
    push([data]);
  };
  return (
    <div>
      <div tw="flex w-full justify-between items-center">
        <span tw="py-1">{data.t[currentLanguage]}</span>
        <span>
          <Button
            onClick={handleClick}
            css={[
              isAsTurn && tw`bg-first text-on-first`,
              !isAsTurn && tw`bg-second text-on-second`,
            ]}
          >
            {sendText}
          </Button>
        </span>
      </div>
      <div tw="border-t-[1px] border-outline mt-1" aria-hidden="true" />
    </div>
  );
};

const TranslationItem = ({
  data,
  push,
  isAsTurn,
  sendText,
  currentLanguage,
}) => {
  if (!data) {
    // TODO:
    return null;
  }
  return data.sentences ? (
    <StarterItem
      data={data}
      push={push}
      isAsTurn={isAsTurn}
      sendText={sendText}
      currentLanguage={currentLanguage}
    />
  ) : (
    <SentenceItem
      data={data}
      push={push}
      isAsTurn={isAsTurn}
      sendText={sendText}
      currentLanguage={currentLanguage}
    />
  );
};

export default TranslationItem;
