import type { PageProps } from "gatsby";

import * as React from "react";
import { Link } from "gatsby";
import tw, { css } from "twin.macro";
import Layout from "../components/Layout";
import { MdArrowBack } from "react-icons/md";
import { ChatBubble, ChatBubbles, ChatBubbleRight } from "../components/icons";
import TopAppBar from "../components/TopAppBar";
import Card from "../components/Card";
import Fab from "../components/Fab";
import { useConversation } from "../data/conversation";
import queryString from "query-string";
import Lasche from "../components/Lasche";
import { usePrimaryLanguage } from "../hooks/settings";
import useConversationInterface from "../hooks/useConversationInterface";

function extractFromSearch(key: string, search?: string) {
  if (!search) {
    return undefined;
  }
  const { id, new: newConversation } = queryString.parse(search);

  if (newConversation) {
    return null;
  }
  return id;
}
const closeThreshold = 30;
function isCloseToBottom() {
  return (
    document.body.scrollHeight - window.scrollY - window.innerHeight <
    closeThreshold
  );
}
function scrollToBottom() {
  window.scrollTo(0, document.body.scrollHeight);
}
function scrollToBottomIfClose() {
  if (isCloseToBottom()) {
    window.scrollTo(0, document.body.scrollHeight);
  }
}

// markup
const ConversationPage = ({ location }: PageProps) => {
  const id = extractFromSearch("id", location?.search);
  const { conversation, push, editTitle } = useConversation(id); // TODO: refactor inital creation -> should be made when Neues Gespräch button is clicked and the redirected afterwards.
  const [primaryLanguage] = usePrimaryLanguage();
  const [editTitleMode, setEditTitleMode] = React.useState(false);
  const languageA = primaryLanguage || "UK",
    languageB = "DE"; // TODO: provide modal for chosing language
  const interfaceLabels = useConversationInterface([languageA, languageB]);
  const [languageState, setLanguageState] = React.useState({
    current: languageA,
    other: languageB,
  });
  const [title, setTitle] = React.useState(
    (!!conversation && conversation.title) ||
      interfaceLabels[primaryLanguage]?.untitled ||
      interfaceLabels[languageA]?.untitled,
  );
  const [overlayOpen, setOverlayOpen] = React.useState(false);

  function swapLanguage() {
    setLanguageState(({ current, other }) => ({
      current: other,
      other: current,
    }));
  }

  React.useEffect(() => {
    if (conversation && conversation.title) {
      setTitle(conversation.title);
    }
  }, [conversation]);

  React.useLayoutEffect(() => {
    scrollToBottomIfClose();
  }, [languageState.current]);

  function openDialogPanel() {
    setOverlayOpen(true);
  }
  function closeDialogPanel() {
    setOverlayOpen(false);
  }
  function pushAndCloseDialogPanel(
    sentences,
    starter?: { [key: string]: any },
  ) {
    if (push) {
      push(languageState.current === languageA ? "A" : "B", sentences, starter);
    }
    setOverlayOpen(false);
    setTimeout(scrollToBottom); // TODO: feature request: offer a scrollTo button if not in close bottom area
  }

  const isAsTurn = languageState.current === languageA;
  const isBsTurn = !isAsTurn;

  const interfaceA = interfaceLabels[languageA],
    interfaceB = interfaceLabels[languageB];

  React.useEffect(() => {
    scrollToBottom();
  }, []);

  function startTitleEditing() {
    setEditTitleMode(true);
  }

  return (
    <Layout>
      <TopAppBar tw="fixed left-0 top-0 w-screen bg-bg">
        <TopAppBar.Icon as={Link} to="/">
          <MdArrowBack />
        </TopAppBar.Icon>
        <TopAppBar.Title>{title}</TopAppBar.Title>
      </TopAppBar>
      <div tw="fixed flex left-1 right-1 bottom-1">
        <Fab
          onClick={isBsTurn ? swapLanguage : openDialogPanel}
          css={[
            tw`flex-grow ml-0.5 transition-colors flex-col h-auto whitespace-normal space-x-0 space-y-0.5 justify-start min-h-[100px]`,
            isAsTurn && tw`bg-first text-on-first`,
            isBsTurn && tw`bg-neutral text-on-neutral`,
          ]}
        >
          {isBsTurn ? <ChatBubbles /> : <ChatBubble />}
          <span>
            {isBsTurn ? (
              <React.Fragment>
                {interfaceA.translate}
                <div tw="text-label-sm">{interfaceB.translate}</div>
              </React.Fragment>
            ) : (
              interfaceA.newMessage
            )}
          </span>
        </Fab>
        <Fab
          onClick={isAsTurn ? swapLanguage : openDialogPanel}
          css={[
            tw`flex-grow ml-0.5 transition-colors flex-col h-auto whitespace-normal space-x-0 space-y-0.5 justify-start min-h-[100px]`,
            isAsTurn && tw`bg-neutral text-on-neutral`,
            isBsTurn && tw`bg-second text-on-second`,
          ]}
        >
          {isAsTurn ? <ChatBubbles /> : <ChatBubbleRight />}
          <span>
            {isAsTurn ? (
              <React.Fragment>
                {interfaceB.translate}
                <div tw="text-label-sm">{interfaceA.translate}</div>
              </React.Fragment>
            ) : (
              interfaceB.newMessage
            )}
          </span>
        </Fab>
      </div>
      <div tw="flex flex-col justify-end pt-9 pb-14.5 min-h-screen">
        {!!conversation &&
          !!conversation?.id &&
          conversation.bubbles.map(
            ({ id, speaker, t }, index: number, bubbles) => (
              <div
                key={`${index}-${id}`}
                css={[
                  speaker === "A"
                    ? tw`pr-8 pl-1 mr-auto`
                    : tw`pl-8 pr-1 ml-auto`,
                  index > 0 &&
                    bubbles[index - 1].speaker === speaker &&
                    tw`mt-1`,
                  index > 0 &&
                    bubbles[index - 1].speaker !== speaker &&
                    tw`mt-3`,
                ]}
              >
                <Card
                  css={[
                    tw`transition-colors`,
                    speaker === "A" &&
                      isAsTurn &&
                      tw`bg-first-light text-on-first`,
                    speaker === "B" &&
                      isBsTurn &&
                      tw`bg-second-light text-on-second`,
                  ]}
                >
                  <Card.Content>{t[languageState.current]}</Card.Content>
                </Card>
                <p tw="text-body-sm px-2 pt-[2px] pb-0.5 text-default/50">
                  {t[languageState.other]}
                </p>
              </div>
            ),
          )}
      </div>
      <Lasche
        show={overlayOpen}
        handleClose={closeDialogPanel}
        handlePushAndClose={pushAndCloseDialogPanel}
        currentLanguage={languageState.current}
        isAsTurn={isAsTurn}
        isBsTurn={isBsTurn}
      />
    </Layout>
  );
};

export default ConversationPage;
