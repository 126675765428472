import { useStaticQuery, graphql } from "gatsby";
import { filterLanguage, language } from "./_helpers";

export default function useConversationInterface(
  language: language | language[],
) {
  const result = useStaticQuery(graphql`
    query UseConversationInterface {
      allInterface {
        edges {
          node {
            _language
            untitled
            translate
            newMessage
            sendMessage
            start
            search
            topics
            topic
            texts
            conversationPartials
          }
        }
      }
    }
  `);
  return filterLanguage(result, language || "UK");
}
