import * as React from "react";
import { Dialog, Transition, Tab } from "@headlessui/react";
import HorizontalTiles from "../components/HorizontalTiles";
import ContentContainer from "../components/ContentContainer";
import { MdClose } from "react-icons/md";
import { views } from "../../content";
import Card from "../components/Card";
import { useCategories, useItems } from "../data/data";
import Button from "../components/Button";
import TranslationItem from "../components/TranslationItem";
import TopAppBar from "../components/TopAppBar";
import "twin.macro";
import useConversationInterface from "../hooks/useConversationInterface";
import tw from "twin.macro";

const topicIconMap = {
  Allgemein: "🤓",
  Notfall: "🚨",
  Gesundheit: "🏥",
  Essen: "🥪",
  Einkaufen: "🛒",
  Amt: "🏢",
};

function ItemList({ currentLanguage, push, isAsTurn, where, ...props }) {
  const interfaceLabels = useConversationInterface(currentLanguage);
  const items = useItems(where);
  return (
    <div tw="flex flex-col space-y-1" {...props}>
      {items.map((data) => (
        <TranslationItem
          key={`${data.id}-${data.sentences ? "starter" : "sentence"}`}
          data={data}
          push={push}
          currentLanguage={currentLanguage}
          isAsTurn={isAsTurn}
          sendText={interfaceLabels.sendMessage}
        />
      ))}
    </div>
  );
}

export default function Lasche({
  show,
  handleClose,
  handlePushAndClose,
  currentLanguage,
  isAsTurn,
  isBsTurn,
  ...props
}) {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [lastCategory, setLastCategory] = React.useState(-1);
  const categories = useCategories();
  const interfaceLabels = useConversationInterface(currentLanguage);

  function createCategoryHandler(id) {
    return () => {
      setLastCategory(id);
      setTabIndex(1);
    };
  }

  return (
    <Transition show={show}>
      <Dialog
        onClose={handleClose}
        tw="fixed flex flex-col left-[0px] top-[0px] w-screen h-screen z-10 overflow-y-auto bg-bg text-default"
        {...props}
      >
        <Dialog.Overlay />
        <Dialog.Title>
          <TopAppBar>
            <TopAppBar.Icon onClick={handleClose}>
              <MdClose />
            </TopAppBar.Icon>
            <TopAppBar.Title>
              {interfaceLabels.conversationPartials}
            </TopAppBar.Title>
          </TopAppBar>
        </Dialog.Title>
        <Tab.Group selectedIndex={tabIndex} onChange={setTabIndex}>
          <Tab.List>
            <HorizontalTiles tw="mt-2">
              <Tab>
                {({ selected }) => (
                  <Button
                    outlined
                    focused={selected}
                    as="div"
                    css={[
                      selected &&
                        isAsTurn &&
                        tw`bg-first text-on-first hover:bg-first`,
                      selected &&
                        !isAsTurn &&
                        tw`bg-second text-on-second hover:bg-second`,
                    ]}
                  >
                    {interfaceLabels.start}
                  </Button>
                )}
              </Tab>
              <Tab disabled={lastCategory < 0}>
                {({ selected }) => (
                  <Button
                    outlined
                    focused={selected}
                    as="div"
                    css={[
                      selected &&
                        isAsTurn &&
                        tw`bg-first text-on-first hover:bg-first`,
                      selected &&
                        !isAsTurn &&
                        tw`bg-second text-on-second hover:bg-second`,
                      lastCategory < 0 &&
                        tw`text-on-neutral/50 bg-neutral-container border-neutral`,
                    ]}
                  >
                    {lastCategory < 0
                      ? interfaceLabels.topic
                      : categories.find((c) => c.id === lastCategory).t[
                          currentLanguage
                        ]}
                  </Button>
                )}
              </Tab>
              {/* TODO: Later featuer:  <Tab>
                        <Button as="div">Gespeicherte</Button>
                      </Tab> */}
              <Tab>
                {({ selected }) => (
                  <Button
                    outlined
                    focused={selected}
                    as="div"
                    css={[
                      selected &&
                        isAsTurn &&
                        tw`bg-first text-on-first hover:bg-first`,
                      selected &&
                        !isAsTurn &&
                        tw`bg-second text-on-second hover:bg-second`,
                    ]}
                  >
                    {interfaceLabels.search}
                  </Button>
                )}
              </Tab>
            </HorizontalTiles>
          </Tab.List>
          <Tab.Panels tw="overflow-y-auto pt-2 pb-6">
            <Tab.Panel>
              <ContentContainer>
                <div tw="text-title-md font-bold">{interfaceLabels.topics}</div>{" "}
                {/* TODO: dynamic */}
              </ContentContainer>

              {!!categories && categories.length && (
                <HorizontalTiles tw="mt-2">
                  {categories
                    .filter((c) => c.t.DE !== "Allgemein")
                    .map(({ id, t }) => (
                      <Card
                        tw="w-16 h-12.5"
                        key={id}
                        onClick={createCategoryHandler(id)}
                      >
                        <Card.Icon tw="text-[32px]">
                          {topicIconMap[t.DE] || null}
                        </Card.Icon>
                        <Card.Title tw="text-body-sm text-center">
                          {t[currentLanguage]}
                        </Card.Title>
                      </Card>
                    ))}
                </HorizontalTiles>
              )}
              <ContentContainer tw="mt-4">
                <div tw="text-title-md font-bold">{interfaceLabels.texts}</div>
                <ItemList
                  currentLanguage={currentLanguage}
                  push={handlePushAndClose}
                  isAsTurn={isAsTurn}
                  tw="mt-2"
                />
              </ContentContainer>
            </Tab.Panel>
            <Tab.Panel>
              <ContentContainer>
                <div tw="text-title-md font-bold">{interfaceLabels.texts}</div>

                <ItemList
                  currentLanguage={currentLanguage}
                  push={handlePushAndClose}
                  isAsTurn={isAsTurn}
                  tw="mt-2"
                  where={(obj) => {
                    return (
                      !!obj.categories && obj.categories.includes(lastCategory)
                    );
                  }}
                />
              </ContentContainer>
            </Tab.Panel>
            <Tab.Panel>Content 3</Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Dialog>
    </Transition>
  );
}
